@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700&display=swap");
$gradient-primary-start: linear-gradient(90deg, #8251e8 2.5%, #16bcff 135%);
$gradient-primary-end: linear-gradient(272deg, #8251e8 2.5%, #16bcff 135%);
$gradient-primary-top: linear-gradient(180deg, #8251e8 2.5%, #16bcff 100%);
$gradient-primary-bottom: linear-gradient(0deg, #8251e8 2.5%, #16bcff 100%);
:root {
  --blue: #18B9FE; 
  --pink: #FF3273;
}


.btnOne{border:solid 1px var(--blue)!important}
.custoMarg{ margin:0% 0 2% 0;}
.textPink{color: var(--pink);}
.solution{ margin:12px 0;border-radius:10px; padding: 15px; text-align: center; border: 1px solid #7C60AA;}
 p { font-size:1.2rem!important; font-weight: light!important;}

body {
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Serif", serif;
  font-family: "Poppins", sans-serif;
  background-color: var(--bs-dark);
  background: url("/images/Home BG.jpg");
  background-size: 100% auto;
  backdrop-filter: blur(50px);
  min-height: 100vh;
}
.modal {
  height: 100vh;
}
.bg-semi-transparent {
  background: rgba(70, 47, 116, 0.26) !important;
}

.gradient-start {
  background: $gradient-primary-start;
}
.gradient-end {
  background: $gradient-primary-end;
}
.gradient-top {
  background: $gradient-primary-top;
}
.gradient-bottom {
  background: $gradient-primary-bottom;
}

.text-gradient-start {
  background: $gradient-primary-start;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-end {
  background: $gradient-primary-end;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-top {
  background: $gradient-primary-top;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-bottom {
  background: $gradient-primary-bottom;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-gradient {
  border: 0;
  background: $gradient-primary-end;
  box-shadow: 0 0 0 3px rgba(var(--bs-info-rgb), 0.15), 0px 3px 20px rgba(91, 226, 255, 0.39);
  color: white;
  &:hover {
    box-shadow: 0 0 0 3px rgba(var(--bs-info-rgb), 0.3), 0px 3px 30px rgba(91, 226, 255, 0.39);
    color: white;
  }
}
.btn-outline-gradient {
  border: 0;
  box-shadow: 0 0 0 2px rgba(var(--bs-info-rgb), 0.5);
  color: white;
  &:hover {
    box-shadow: 0 0 0 2px rgba(var(--bs-info-rgb), 0.7), 0px 3px 20px rgba(91, 226, 255, 0.39);
    color: white;
  }
}

.border-transparent {
  border-color: transparent !important;
}

aside .nav-item {
  transition: 300ms;
  &:hover {
    background-color: rgba($color: var(--bs-primary-rgb), $alpha: 0.1);
  }
}

.nav-pills {
  .nav-link.active {
    background: $gradient-primary-end;
  }
  .nav-link {
    &:hover {
      background-color: rgba($color: var(--bs-primary-rgb), $alpha: 0.1);
    }
  }
}

.form-floating label::after {
  background-color: transparent !important;
}

.rounded-top-left-0 {
  border-top-left-radius: 0 !important;
}
.rounded-top-right-0 {
  border-top-right-radius: 0 !important;
}
.rounded-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}
.rounded-bottom-right-0 {
  border-bottom-right-radius: 0 !important;
}

.cell {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  line-height: 1.1;
  border-left: 0px !important;
  &:not(:first-child) {
    border-top: 0px !important;
  }
}

.blog-thumbnail {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;
}

option {
  background-color: rgba($color: var(--bs-dark-rgb), $alpha: 0.75);
}
.select-dark {
  background-color: rgba($color: var(--bs-primary-rgb), $alpha: 0.25);
  border: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 6px;
  &::after {
    color: var(--bs-info);
    font-size: 22px;
    font-weight: 700;
  }
}

.blog-title {
  color: #ffffff;
  text-decoration: none;
}


.nav-link { font-size: 17px!important;
  color: white !important; font-weight:normal!important;
  margin-right: 15px;
} 
.nav-link:hover{color: var(--blue)!important;}
.dropdown-item{font-size: 17px!important;
  color: white !important; font-weight:normal!important;}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  color: white !important; 
}

.dropdown-menu{background: #342568!important;}
 
.navbar-nav .nav-item + .nav-item {
  margin-left: 15px;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color:var(--blue)!important;
}
.dropdown-item:hover{background-color:var(--blue)!important;}
.dropdownItem:hover{background-color:var(--blue)!important;}

.dropdownItem{ padding:0px 8px!important;}
.dropdownItem .nav-link:hover{color:#fff!important;}

.Headbg{ padding:0px 0 60px 0px;
  background: #270A6D; margin: 0 0 5% 0;
}

.custList{ display: inline-flex!important;}
.custList li{ margin:0 15px; list-style: none; font-weight: 500; color: #ffd000!important; }

.borderBot{ border-bottom: 1px solid #7C60AA;}
.borderTop{ border-top: 1px solid #7C60AA;}
.blockOne{ padding:20px; margin:20 0;}

.disDesk{ display: inline-block!important;}
.disDesk li{ }
.socialLinks a{margin:5px; display: inline-block;}





@media (max-width: 768px) {
  .dropdownItem:hover {
    background-color: transparent!important;
}
  .blockDis{display: block!important;
    text-align: center!important;
  }
  .custoAlign{ text-align: center!important;}
  .navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: #fff!important;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
  }
}