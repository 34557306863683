$primary: #7657dd;
$secondary: #4022a7;
$info: #38b1ff;
// $light: #dfebe8;
$dark: #12023e;
$success: #49bf2c;
$warning: #ff833d;
// $danger: #f10705;

$input-bg: transparent;
$input-color: white;
$input-placeholder-color: $primary;
$input-border-color: $primary;
$input-focus-border-color: $info;
// $input-border-width: 2px;
$form-check-input-bg: $primary;
$form-check-input-border: transparent;

$accordion-color: white;
$accordion-bg: transparent;
$accordion-border-color: $primary;
$accordion-button-color: #aa90ff;
$accordion-button-bg: $dark;
$accordion-icon-color: $primary;

$form-select-indicator-color: white;
@import "bootstrap/scss/bootstrap.scss";
